#containerTip {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 110px;
  padding: 20px 30px;
  border-radius: 10px;
  background: var(--blue);

  p {
    font-size: 13px;
    font-weight: 500;
    color: var(--white);
    margin-top: 10px;

    a {
      color: var(--white);
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    h2 {
      font-size: 16px;
      color: var(--white);
      margin-left: 4px;
    }
  }
}