.containerRange {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 110px;
  padding: 20px;

  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;

  .descriptionRange {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    svg {
      path {
        fill: var(--color-icons);
      }
    }
    span {
      font-size: 14px;
      color: var(--text);
      margin-left: 8px;
    }
  }
}

.rc-slider {
  margin-top: 18px;
  width: 94%;
  &.rc-slider-with-marks {
    width: 94%;
  }

  .rc-slider-rail {
    box-shadow: 5px 5px 25px #0000000d;
  }

  .rc-slider-handle {
    margin-top: -5.9px;
  }

  .rc-slider-step {
    .rc-slider-dot {
      border: 2px solid transparent;
      background-color: transparent;
      cursor: none;
    }
  }

  .rc-slider-mark {
    top: 28px;

    .rc-slider-mark-text {
      color: #7a8dab;
      &.rc-slider-mark-text-active {
        color: #000;
      }
    }
  }
}

.rc-slider-tooltip {
  .rc-slider-tooltip-content {
    -webkit-tap-highlight-color: rgba(230, 46, 46, 0);
    .rc-slider-tooltip-arrow {
      border-top-color: rgb(0, 0, 0);
    }
    .rc-slider-tooltip-inner {
      background-color: rgb(0, 0, 0);
      padding: 7px 2px;
      min-width: 44px;
      height: 28px;
    }
  }
}
