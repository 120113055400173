.overlayModal {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}