#containerShare {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 23px;

  .social {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    width: 42px;
    height: 42px;
    background: #EFEFEF;
    border: 1px solid #fff;
    transition: width 0.3s ease-in-out;

    span {
      font-size: 0px;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: none;
      background: #32508B;
      flex-shrink: 0;
      z-index: 10;
    }
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 42px;
    height: 42px;
    background: #76809F;
    border: 1px solid #fff;
    z-index: 10;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid transparent;
      background: transparent;
    }
  }
}

