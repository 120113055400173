#container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 55px;

  background: var(--bg-settings);
  border: var(--border-settings);
  box-shadow: 5px 5px 25px #0000000D;
  border: var(--border-settings);
  border-radius: 10px;
  padding: 14px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: 10px;
    color: var(--white);

    width: 20px;
    height: 20px;

    border-radius: 20px;
    background: var(--blue);

    &.counterZero {
      background: #9B9FAB;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    font-size: 14px;
    color: var(--text);
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;

      border: none;
      background: transparent;
      margin-right: 20px;
    }
    
  }
}