#linkUi {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;

  padding: 14px;

  width: 100%;
  height: 55px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    color: #474747;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-linkUi);
    border: 1px solid var(--white);
    box-shadow: 5px 5px 25px #0000000d;
    border: var(--border-settings);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: -1px;
    bottom: -1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: var(--blue);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 14px;
    color: var(--text);

    svg {
      margin-right: 12px;
      path {
        stroke: var(--text);
      }
    }

    span {
      font-size: 14px;
      color: var(--text);
      margin-left: 7px;
    }
  }
}
