#myPages {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;

  @media (max-width: 879px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 100%;

  }

  .createNewPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 350px;

    background: var(--blue);
    border-radius: 20px;

    @media (max-width: 879px) {
      width: 100%;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

      border: none;
      background: transparent;
    }

    span {
      font-size: 20px;
      color: var(--white);
      margin-top: 25px;
    }
  }
  .no-animated {
    text-decoration: none;
    position: relative;
    overflow: hidden;
    &.animated {
      animation: zoomIn .2s linear;
    }
  }

  .pageCreated {
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;

    height: 350px;
    text-decoration: none;

    background: var(--bg-panel);
    border:var(--border);
    border-radius: 20px;
    padding: 28px 30px;
    position: relative;

    @media (max-width: 879px) {
      width: 100%;
    }

    .divRelative {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .imagePage {
        display: grid;
        place-items: center;
        img {
          width: 88px;
          height: 88px;
          object-fit: cover;
          border-radius: 20px;
        }
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 10;
      }

      ul li button {
        border: none;
        background: transparent;
        padding: 5px;

        svg {
          path {
            stroke: var(--color-icons);
          }
        }
        
      }
      li + li {
        margin-left: 10px;
      }
    }
    .descriptionMain {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin-top: 20px;

      .descriptionPage {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        .buttonTitlePage {
          border: none;
          background: transparent;
          font-size: 18px;
          color: var(--text);
          text-align: left;
        }

        .sectionDescription {
          font-size: 14px;
          color: #9e9e9e;
          margin-top: 8px;
          height: 80px;
          overflow-y: scroll;
          width: 100%;
          word-wrap: break-word;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .dataAltered {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        width: 100%;
        height: 55px;
        padding: 10px;
        border: none;
        border-radius: 15px;
        margin-top: 8px;
        font-size: 0.875rem;
        background: #d5d5d5;
        color: #676767;

        p{
          font-size: 13px;
        }
      }
    }
  }
}
