#containerTextEdit {
  display: flex;
  flex-direction: column;

  .buttonCopyText {
    text-align: center;
    width: 100%;
    border: none;
    background: transparent;
    font-weight: 400;

    footer {
      word-break: break-word;
      p {
        span {
          &.text-tiny {
            font-size: 0.7em;
          }
          &.text-small {
            font-size: 0.85em;
          }
          &.text-default {
            font-size: 1em;
          }
          &.text-big {
            font-size: 1.4em;
          }
          &.text-huge {
            font-size: 1.8em;
          }
        }
      }
      h2 {
        span {
          &.text-tiny {
            font-size: 0.7em;
          }
          &.text-small {
            font-size: 0.85em;
          }
          &.text-default {
            font-size: 1em;
          }
          &.text-big {
            font-size: 1.4em;
          }
          &.text-huge {
            font-size: 1.8em;
          }
        }
      }
      h3 {
        span {
          &.text-tiny {
            font-size: 0.7em;
          }
          &.text-small {
            font-size: 0.85em;
          }
          &.text-default {
            font-size: 1em;
          }
          &.text-big {
            font-size: 1.4em;
          }
          &.text-huge {
            font-size: 1.8em;
          }
        }
      }

      h4 {
        span {
          &.text-tiny {
            font-size: 0.7em;
          }
          &.text-small {
            font-size: 0.85em;
          }
          &.text-default {
            font-size: 1em;
          }
          &.text-big {
            font-size: 1.4em;
          }
          &.text-huge {
            font-size: 1.8em;
          }
        }
      }
    }
  }

  .buttonTextDefault {
    word-break: break-word;
    border: none;
    padding: 4px;
  }
}
