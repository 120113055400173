#addContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  margin-top: 25px;

  section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .mainAddContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .contentDescription {
      display: flex;
      align-items: center;
      gap: 22px;

      h3 {
        font-size: 16px;
        color: var(--blue);
      }
    }

    .iconDropContent {
      transition: all 0.35s ease;
      display: grid;
      place-items: center;
    }
  }

  footer.open{
    display: flex;
    width: 100%;
    margin-top: 28px;
    padding: 16px 15px;

    background: var(--bg-settings);
    box-shadow: 10px 10px 15px #00000005;
    border: var(--border-settings); 
    border-radius: 20px;

    >div {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
      grid-gap: 12px;
      gap: 12px;
      width: 100%;
    }

    .drag-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      padding: 15px 20px;


      border: none;
      border-radius: 20px;
      transition: all 0.3s;
      
      &:hover {
        background: var(--white);
        cursor: move;
        box-shadow: 0px 10px 15px #00000008;

        span {
          color: #000;
        }
      }

      span {
        font-size: 14px;
        color: var(--text);
        text-align: center;
        margin-top: 4px;
        white-space: nowrap;
      }
    }
  }

  footer.close {
    display: none;    
  }

  &.active {
    background: var(--white);
    box-shadow: 0px 10px 15px #00000008;
    border-radius: 20px;
  }
}
