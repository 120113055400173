
.ImageEdits {
  display: grid;
  place-items: center;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
