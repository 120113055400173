#header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 22px 70px;
  background: var(--bg-panel);
  box-shadow: 13px 13px 50px #0000000d;
}

.headerContentRight {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 700px;

  .headerSearch {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 325px;
    height: 45px;

    padding: 0 15px;

    background: var(--bg-settings);
    border: var(--border-settings);
    border-radius: 10px;
    box-shadow: 5px 5px 25px #0000000d;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
    }

    svg {
      margin-right: 15px;
    }

    input {
      flex: 1;
      outline: none;
      border: none;
      background: transparent;

      font-size: 14px;
      color: var(--text);
    }
  }

  .headerMenu {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ul li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ul li button {
      border: none;
      background: transparent;
      padding: 5px;
      margin-left: 10px;
    }

    .headerIconHasNotification {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      position: relative;

      svg {
        cursor: pointer;
        margin-left: 20px;
      }

      &.headerIconHasNotification:after {
        position: absolute;
        content: "1";

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 8px;
        color: #fff;

        width: 14px;
        height: 14px;
        bottom: 30px;
        left: 38px;
        border-radius: 50%;
        flex-shrink: 0;
        background: var(--blue);
      }
    }

    .notificationModal {
      position: absolute;
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      right: 20%;
      width: 352px;
      height: 433px;

      background: #ffffff;
      box-shadow: 0px 3px 50px #00000026;
      border-radius: 20px;

      display: flex;
      flex-direction: column;

      header {
        width: 100%;
        text-align: start;

        font-size: 18px;
        color: #000;
        margin: 29px 0 0px 20px;
      }

      main {
        width: 100%;
        display: flex;
        flex-direction: column;

        .infoNotification {
          display: flex;
          padding: 29px 22px;
          border-bottom: 1px solid #d8d8d8;
          p {
            width: 232px;
            font-size: 14px;
            color: var(--blue);
            margin-left: 14px;
            span {
              color: #9b9fab;
            }
          }
          .iconNotification {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 10px;
            background: var(--blue);
          }
        }
      }

      footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        font-size: 14px;
        color: var(--blue);
      }
      &.opened {
        opacity: 1;
        visibility: visible;
        top: 85px;
        animation: slideInDown .5s;
      }
    }
  }


  .headerProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 14px;
      color: var(--text);
    }


  .buttonDropDown {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      border: none;
      background: transparent;
      margin-left: 5px;
    svg {
      transition: 0.3s;
      cursor: pointer;
    }
    &.opened {
      svg {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }

    .headerUser {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin-left: 14px;
      position: relative;

      cursor: pointer;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }

  }
  .userModal {
    position: absolute;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 50;

    top: -500px;
    right: 77px;

    width: 259px;
    height: 291px;

    background: #ffff;
    box-shadow: 0px 3px 50px #00000026;
    border-radius: 20px;

    display: flex;
    flex-direction: column;

    header {
      font-size: 18px;
      color: #000;
      padding: 31px 0 0 38px;
    }

    main {
      border-bottom: 1px solid #EBEBEB;
      padding: 31px 0 24px 38px;

      ul li + li {
        margin-top: 18px;
      }

      ul li a {
        text-decoration: none;
        font-size: 14px;
        color: #808080;
        transition: color 0.3s;
        &:hover {
          color: var(--blue);
        }
      }
    }
    footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 18px 0 0 38px;

      font-size: 14px;
      a {
        color: #B92F2F;
        text-decoration: none;
      }
    }
    &.opened {
      opacity: 1;
      visibility: visible;
      top: 85px;
      animation: slideInDown .5s;
    }
  }
}
