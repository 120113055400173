
.modalEdits {
  .containerModalEdits {
    width: 100%;
    border-radius: 20px 20px 0 0;
    box-shadow: 10px 10px 15px #00000008;
    position: relative;

    header {
      position: absolute;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 20px 20px 0 0;
      width: 100%;

      .closeModal {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .editModal {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    main {
      text-align: center;
      width: 100%;
      min-height: 30px;
      padding: 8px 28px;
      word-wrap: break-word;

      h2 {
        font-size: 1.125rem;
      }
    }
    footer {
      width: 100%;
      height: 100%;
      padding: 15px 15px;
      word-wrap: break-word;

      p {
        color: var(--text);
      }
    }
  }
}
