#containerFormEdit {
  width: 100%;

  .input-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contentFormEdit {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    form {
      width: 100%;

      .buttonFormDefault {
        display: grid;
        place-items: center;
        
        width: 100%;
        height: 48px;
        
        text-align: center;
        border: none;

      }
    }

    .containerTypeText {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 55px;
      padding: 0 14px;
      transition: border 0.3s;

      input {
        width: 100%;
      }

      &.focused {
        border: 1px solid var(--blue);
      }

      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 80%;

        svg {
          margin-right: 10px;
        }
      }

      input {
        font-size: 14px;
        padding: 5px;
        border: none;
        background: transparent;
        height: 55px;
        outline: none;

        &::placeholder {
          color: #9b9fab;
        }
      }

      span {
        font-size: 13px;
        margin-left: 12px;
      }

      .boxHeaderRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
      }

      .required {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-right: 6px;

        border-radius: 50%;
        position: relative;

        span {
          position: absolute;
          align-items: center;
          display: flex;
          justify-content: center;
          opacity: 0;
          transition: 0.3s;

          white-space: nowrap;
          pointer-events: none;
          background: #000000;
          color: #fff;
          border-radius: 5px;
          padding: 5px;
          font-size: 9px;
          bottom: 25px;
          left: -5px;
          z-index: 50;
          &::before {
            content: "";
            position: absolute;
            bottom: -15%;
            left: 5px;
            border-style: solid;
            border-color: #000000 transparent;
            border-width: 6px 6px 0 6px;
          }
        }
        &:hover {
          span {
            opacity: 1;
          }
        }
        .error {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin: 0;
            width: 16px;
            height: 16px;
          }
        }
      }
      .buttonsRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;

        button {
          border: none;
          background: transparent;
        }

        .moveBox {
          &:hover {
            cursor: move;
          }
        }
      }
    }
  }
}
