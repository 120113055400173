#containerVideo {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  .contentVideoExibition {
    width: 100%;
    video {
      width: 100%;
      display: block;
    }
  }

  .destaque {
    width: 100%;
    height: 180px;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    .wallpaper {
      display: flex;
      flex-direction: column;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      width: 100%;
      height: 100%;

      .infoVideo {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: absolute;

        bottom: 10px;
        left: 20px;

        span {
          font-size: 14px;
          font-weight: medium;
          color: var(--white);
          white-space: nowrap;
        }

        figure {
          display: flex;
          align-items: center;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          margin-top: 5px;
        }

        figcaption {
          font-size: 12px;
          font-weight: medium;
          color: var(--white);
          white-space: nowrap;
          margin-left: 9px;
        }
      }
    }
  }
}
