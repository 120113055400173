#containerDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 55px;

  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000D;
  border: var(--border-settings);
  border-radius: 10px;
  padding: 0 14px;

  svg {
    margin-right: 12px;
    path {
      fill: var(--color-icons);
    }
  }


  span {
    font-size: 14px;
    color: var(--text);
  }

  position: relative;

  input[type="date"] {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    
  }
  .datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}