#sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .sliderCarrosel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .imageSlider {
    width: 100%;
    height: 170px;
  }
  
  .sliderRightArrow {
    margin-bottom: 10px;
    border: none;
    background: transparent;
    position: absolute;
    right: 0px;
    z-index: 10;
    cursor: pointer;
    user-select: none;

    svg {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
  
  .sliderLeftArrow {
    border: none;
    background: transparent;
    position: absolute;
    left: 0px;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slideEdit {
    opacity: 0;
  }

  .slideEdit.active {
    display: grid;
    place-items: center;
    width: 100%;
    height: 170px;
    position: relative;
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#editSlide {
  
  .mainPanelRight {
    .includeImage {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 12px;

      max-height: 140px;
      
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .configImage {
        display: grid;
        place-items: center;
        width: 100%;
        position: relative;
        border-radius: 10px;
      
        img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
        }

        .buttonsConfig {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: all 0.3s;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: none;
          }
          button + button {
            margin-left: 5px;
          }
          .buttonConfig {
            background: blue;
          }
          .buttonTrash {
            background: red;
          }

          &:hover {
            opacity: 1;
            background: #FFFFFF96;
          }
        }
      }
    }
  }
}

.openConfigImageModal {
  width: 500px;
  height: 210px;

  background: var(--white);
  border-radius: 20px;

  header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;
    background: var(--blue);
    border-radius: 20px 20px 0 0;

    h2 {
      font-size: 14px;
      color: var(--white);
    }
  }
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 30px;
    background: var(--bg-containerModal);
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 50px;

    .configImageButtonSave {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50px;
      border: none;
      border-radius: 0 0 20px 0;
      background: var(--blue);
      
      color: var(--white);

      &:disabled {
        filter: brightness(0.8);
        cursor: not-allowed;
      }
    }
    .configImageButtonCancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50px;
      border: none;
      border-radius: 0 0 0 20px;
      background: #D4D9E9;
    }
  }
}