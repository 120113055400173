.containerInputPrimary {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 55px;
  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;
  padding: 0 20px;
  color: var(--text);
  transition: border 0.1s;

  svg {
    margin-right: 12px;
    path {
      fill: var(--color-icons);
    }
  }

  &.focused {
    border: 1px solid var(--blue);
  }

  &.error {
    border: 1px solid #e15050;
  }

  &.inputDisabled {
    cursor: not-allowed;
    opacity: 0.4;
    color: #474747;
  }

  input {
    flex: 1;
    font-size: 14px;
    color: var(--text);
    border: 0;
    background: transparent;
    height: 55px;
    outline: none;

    &::placeholder {
      font-size: 14px;
      color: #9B9FAB;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .errorMessage {
    font-size: 14px;
    color: #e15050;
    position: absolute;
    right: 0;
    bottom: 72px;
    animation: fadeInUp .5s linear;
  }
}
