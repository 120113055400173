#panelInfo {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  margin-top: 25px;

  .mainInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      width: 100%;

      span {
        font-size: 16px;
        color: var(--text);
        margin-left: 20px;
      }
    }

    .infoGroup {
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 100%;
      margin-top: 32px;
    }
  }
}
