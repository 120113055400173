
.buttonPrimary {
  width: 100%;
  min-height: 45px;

  word-wrap: break-word;

  >button {
    text-align: center;
    width: 100%;
    min-height: 45px;
    padding: 12px;
  }
}
