
.containerMapWidget {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  
  .buttonMap {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .containerMap {
    width: 100%;
    height: 390px;
  }
}


#editMap {

  .containerMap {
    width: 100%;
    height: 390px;
  }
  
  
  .mainPanelRight {
    .map {
      width: 100%;
      height: 390px;
      border-radius: 20%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .buttonMap {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
        z-index: 10;
      }
    }
  }
}
