.openGalery {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  width: 100%;

  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;

  padding: 20px;

  &.galeryDisabled {
    cursor: not-allowed;
    opacity: 0.4;
    color: #474747;
  }

  header {
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: var(--text);
      margin-left: 8px;
    }

    svg {
      path {
        stroke: var(--color-icons);
      }
    }
  }

  button {
    width: 100%;
    height: 45px;

    background: var(--blue);
    border: none;
    box-shadow: 0px 5px 20px #0766ff26;
    border-radius: 45px;

    color: var(--white);

    &:disabled {
      cursor: not-allowed;
    }
  }

  h3 {
    width: 100%;
    text-align: right;
    font-size: 13px;
    color: var(--color-icons);
  }

  @media (max-width: 1400px) {
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    header {
      margin-bottom: 5px;
    }
  }
}

.sectionLogoPreview {
  display: grid;
  grid-template-columns: 1fr 76px;
  align-items: center;
  gap: 14px;

  width: 100%;
  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;
  padding: 20px;

  .contentInfoGalery {
    display: flex;
    flex-direction: column;
    gap: 14px;

    button {
      width: 100%;
      height: 45px;
  
      background: var(--blue);
      border: none;
      box-shadow: 0px 5px 20px #0766ff26;
      border-radius: 45px;
  
      color: var(--white);
  
      &:disabled {
        cursor: not-allowed;
      }
    }

    .titleGalery {
      display: flex;
      align-items: center;
  
      span {
        font-size: 14px;
        color: var(--text);
        margin-left: 8px;
      }
  
      svg {
        path {
          stroke: var(--color-icons);
        }
      }
    }
  }

  .imagePhoneUi {
    display: grid;
    place-items: center;

    width: 76px;
    height: 76px;
    background-color: lightcoral;

    img {
      width: 76px;
      height: 76px;
      object-fit: cover;
  }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 48px;
    .imagePhoneUi {
      width: 48px;
      height: 48px;

      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }
    }
  }
}

.overlayModal {

  .openGaleryModal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    width: 80%;
    height: 80%;
    padding: 40px;

    background: var(--bg-containerModal);
    box-shadow: 0px 15px 30px #0000000d;
    border-radius: 20px;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .information {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        h1 {
          font-size: 35px;
          color: var(--text);
        }
        span {
          font-size: 14px;
          color: #9b9fab;
          margin-top: 6px;
        }
      }

      .openGaleryButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        width: 100%;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 245px;
        height: 50px;
        font-size: 14px;
        color: var(--white);
        svg {
          margin-right: 6px;
        }
      }
    }

    main {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: 12px;
      width: 100%;
      margin-top: 40px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .containerAlbum {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 210px;

        background: var(--backgroundCardModal);
        border-radius: 20px;
        padding: 15px;
        border: var(--border-settings);

        transition: border 0.2s;
        &:hover {
          border: 1px solid var(--blue);
        }
        .imageAlbum {
          border: none;
          width: 100%;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .informationAlbum {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 14px;

          .titleAlbm {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            border: none;
            background: transparent;
            h2 {
              font-size: 14px;
              color: var(--text);
            }
            span {
              font-size: 12px;
              color: #9b9fab;
            }
          }
          .deleteAlbum {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #d2d2d2;

            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #e61d1d;

              svg {
                path {
                  fill: var(--white);
                }
              }
            }
          }
        }
      }
    }
  }
}
