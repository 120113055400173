#ContainerOffer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 15px;
  width: 100%;
  box-shadow: 0px 10px 20px #0000001A;

  .figure {
    display: grid;
    place-items: center;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .offerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 14px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 39px;
      height: 39px;
      border: none;
      border-radius: 50%;
      flex-shrink: 0;

      transition: filter 0.3s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .offerTitle {
    display: flex;
    flex-direction: column;


    h3 {
      font-size: 14px;
      font-weight: medium;
    }

    .offerSubtitle {
      display: flex;
      align-items: center;
      margin-top: 4px;

      span {
        font-size: 12px;
        font-weight: normal;
        color: #9b9fab;
        text-decoration: line-through;
        margin-right: 5px;
      }

      b {
        font-size: 16px;
        font-weight: normal;
        color: var(--text);
      }
    }
  }
}
