
#CountanierCount {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 5px 5px 20px #00000047;

  width: 100%;
  height: 150px;
  padding: 20px;

  header {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    font-size: 16px;

    svg {
      margin-right: 8px;
    }
  }
  main {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    
    .conteinerTime {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      b {
        font-size: 12px;
      }

      span {
        margin: 0 4px;
      }
    }
    .time {
      display: flex;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 30px;
        border-radius: 5px;
        font-size: 20px;
      }
    }
  }
}

