.inputRadio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  margin-top: 30px;

  & + & {
    margin-top: 10px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    border-radius: 50%;
    flex-shrink: 0;
    width: 15px;
    height: 15px;

    font-weight: bold;

    border: 1px solid var(--white);
    background-color: #efefef;

    transition: 0.2s all linear;
    margin-right: 5px;
  }

  input:checked {
    border: 4px solid var(--blue);
  }

  input:checked ~ span {
    color: var(--blue);
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    cursor: pointer;
    color: var(--text);
  }
}

// INPUTCHECK

.inputCheck {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--text);

}
.inputCheck + .inputCheck {
  margin-top: 10px;
}

.inputCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: 1px solid #fff;
  background-color: #efefef;

  transition: background-color 0.3s;
}

input[type="checkbox"]:checked ~ .checkmark {
  background-color: var(--blue);
  border: 0;
}

input[type="checkbox"]:checked ~ span {
  color: var(--blue);
}

.checkmark svg {
  content: "";
  position: absolute;
  display: none;
  border: none;
}

input[type="checkbox"]:checked ~ .checkmark svg {
  display: block;
}

.inputCheck .checkmark svg {
  left: 3px;
  top: 3.2px;
}

// INPUTRANGESLIDER
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-linkUi);
  border: 1px solid var(--white);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: -1px;
  bottom: -1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#inputRangeSlider {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--bg-settings);
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  border-radius: 10px;

  padding: 14px;

  width: 100%;
  height: 55px;

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 14px;
    color: var(--text);

    svg {
      margin-right: 12px;
    }

    span {
      font-size: 14px;
      color: var(--text);
      margin-left: 7px;
    }
  }
}
