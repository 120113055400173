#containerDownload {
  display: flex;
  align-items: flex-start;

  width: 100%;

  .contentRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 63px;
      height: 63px;
      border: none;

      .containerIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 49px;
        height: 49px;
        border: none;
      }
    }

    span {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      margin-top: 12px;
      word-wrap: break-word;
      max-width: 200px;
    }
  }
}
