.containerRadio {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--black);

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 15px;
    height: 15px;
    border-radius: 50%;
    flex-shrink: 0;

    border: 1px solid var(--white);
    background-color: #efefef;

    transition: 0.2s all linear;
    margin-right: 10px;
  }

  span {
    font-size: 13px;
    transition: all 0.35s ease;

    &:hover {
      color: #0766FF !important;
    }
  }
}
