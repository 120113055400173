#ContainerColumn {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  .videoBg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #232a34;
    }
  }

  .columnsFixed {
    width: 100%;
    display: none;
  }

  .columns {
    display: flex;
    flex-direction: row;
    min-height: 45px;

    .item-column {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      border: 2px dashed #ffffff94;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        div {
          // &.widget-offer {
          //   #ContainerOffer {
          //     padding: 2px;

          //     .offerInfo {
          //       button {
          //         width: 30px;
          //         height: 30px;
          //       }
          //     }
          //   }
          // }

          // &.widget-contador {
          //     #CountanierCount {
          //       padding: 8px;

          //       main {
          //         display: grid;
          //         grid-template-columns: 1fr 1fr 1fr;
          //         width: 100%;
          //         gap: 8px;
          //         margin-top: 10px;

          //         span {
          //           display: flex;
          //           align-items: center;
          //           justify-content: center;
          //         }

          //         span:nth-child(4) {
          //           display: none;
          //         }
          //       }
          //     }
          // }

          &.widget-form {
            width: 100%;
          }

          &.widget-contador {
            width: 100%;
          }

          &.widget-slide {
            width: 100%;
          }

          &.widget-button {
            width: 100%;
          }
          &.undefined {
            #containerVideo {
              .destaque {
                width: 124px;
                .infoVideo {
                  left: 4px;
                  span {
                    font-size: 11px;
                    white-space: normal;
                  }

                  figure {
                    figcaption {
                      font-size: 11px;
                      white-space: normal;
                    }
                  }
                }

                .overlay-play {
                  top: -17px;
                }
              }
            }
          }
        }
      }
    }
  }

  .column {
    &.column-3 {
      .item-column {
        &:nth-child(2) {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }
  }

  #templates {
    #creation {
      .creation-layout {
        .columns {
          .item-column {
            > div {
              border: 1px solid rgba($color: #000000, $alpha: 0.2);
            }
          }
        }
      }
    }
  }
}

.column {
  &.column-2 {
    #ContainerColumn {
      .columns {
        .item-column {
          div {
            .widget-offer {
              #ContainerOffer {
                padding: 2px;
                .offerInfo {
                  button {
                    width: 30px;
                    height: 30px;
                  }
                  align-items: end;
                  .offerTitle {
                    .offerSubtitle {
                      flex-direction: column;
                      align-items: baseline;
                    }
                  }
                }
              }
            }

            .widget-contador {
              #CountanierCount {
                background-color: lightblue !important;
                padding: 8px;
                height: auto;
                header {
                  font-size: 14px;
                  flex-direction: column;
                  gap: 4px;

                  svg {
                    margin-right: 0px;
                  }
                }
                main {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  width: 100%;
                  gap: 4px;
                  margin-top: 10px;

                  .containerTime {
                    .time {
                      span {
                      width: 34px;
                      height: 28px;
                      border-radius: 5px;
                      font-size: 15px;
                      }
                    }
                  }

                  span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  span:nth-child(4) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.column {
  &.column-3 {
    #ContainerColumn {
      .columns {
        .item-column {
          div {
            .widget-offer {
              #ContainerOffer {
                .offerInfo {
                  flex-direction: column;
                  align-items: center;
                  gap: 4px;
                  .offerTitle {
                    align-items: center;
                    text-align: center;
                    .offerSubtitle {
                      flex-direction: column;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.column.column-4 {
  #ContainerColumn {
    .columns {
      .item-column {
        div {
          .offerInfo {
            flex-direction: column;
            padding: 5px;
            .offerTitle {
              div {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}

.column {
  &.column-3 {
    #ContainerColumn {
      .columns {
        .item-column {
          div {
            .widget-contador {
              #CountanierCount {
                padding: 2px;
                header {
                  font-size: 14px;
                }
                main {
                  gap: 0;
                  .time {
                    span {
                      width: 35px;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.column {
  &.column-4 {
    #ContainerColumn {
      .columns {
        .item-column {
          div {
            .widget-contador {
              #CountanierCount {
                padding: 4px;
                header {
                  font-size: 12px;
                  flex-direction: column;
                  text-align: center;
                }
                main {
                  gap: 0;
                  .time {
                    span {
                      width: 27px;
                      font-size: 12px;
                      margin: 0;
                    }
                    b {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
