#paginationFlow {
  display: flex;
  flex-direction: column;    
  width: 100%;
  height: calc(100vh - 170px);

  .headerPagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .headerInfoLeft {
      display: flex;
      align-items: center;

      .headerBackToPage {
        display: flex;
        align-items: center;

        .buttonBackToPage {
          display: grid;
          place-items: center;

          border: none;
          background: transparent;

          padding: 8px;
          margin-right: 12px;

          svg {
            path {
              fill: var(--text);
            }
          }
        }
      }

      h1 {
        font-size: 40px;
        font-weight: medium;
        color: var(--text);
        margin-right: 24px;
      }

      .headerTotalPage {
        display: flex;
        flex-direction: column;

        border-left: 1px solid #cecece;
        padding-left: 24px;

        h2 {
          font-size: 16px;
          font-weight: bold;
          color: var(--text);
        }

        span {
          font-size: 13px;
          font-weight: medium;
          color: var(--label);
          margin-top: 4px;
        }
      }
    }
    .headerInfoRight {
      display: flex;
      align-items: center;
      gap: 1rem;

      .buttonSettings {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        border-radius: 4px;
        border: none;

        font-size: 1rem;
        color: #FFF;
        outline: none;
        padding: 10px 12px;
        cursor: pointer;
        transition: all .3s;

        &.add {
          background: var(--blue);
        }

        &.save {
          background: #24a36e;
        }

        &.reset {
          background: #859EC5;
        }

        &.delete {
          background: #E15050;
        }

        svg {
          path {
            fill: #fff;
          }
        }
        &:hover {
          transform: scale(1.05);
          box-shadow:0px 10px 10px rgba(0,0,0,.1)
        }
      }
    }
  }

  .containerPaginationFlow {
    display: flex;

    width: 100%;
    height: calc(100vh - 268px);
    background: var(--bg-panel);
    border-radius: 4px;
    margin-top: 20px;

    .controls {
      display: flex;
      gap: 12px;
      z-index: 999;

      button {
        z-index: 9999;
      }
    }

    .react-flow {
      &.notStorytelling {
        .react-flow__handle {
          display: none;
        }
      }

      .react-flow__renderer {
        .react-flow__edge {
          path {
            stroke: #0766ff;
          }
          &.selected {
            path {
              stroke: #555;
            }
          }
        } 
      }
    }

    .react-flow__node-input {
      background: #0766ff;
      border: none;
      border-radius: 4px;
      color: #fff;
      text-transform: capitalize;

      .react-flow__handle {
        background: #0766ff;
      }
    }
  }
  
}
