#containerSignUp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .contentLeft {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
    height: 100vh;
    background: #0766ff;
    padding-left: 20px;

    .sectionlogo {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0766ff;
      width: 400px;
      height: var(--heightPanel);
      border-radius: 20px 0px 0px 20px;
      border-top: 1px solid RGBA(235, 235, 235, 0.1);
      border-left: 1px solid RGBA(235, 235, 235, 0.1);
      border-bottom: 1px solid RGBA(235, 235, 235, 0.1);
      box-shadow: 13px 13px 50px rgba(#000, 0.2);
      position: relative;
      z-index: 2;
    }

    @media (max-width: 640px) {
      .sectionlogo {
        display: none;
      }
    }
  }

  .contentRight {
    display: flex;
    align-items: center;
    width: 55%;
    height: 100vh;
    background: var(--input);
    padding-right: 20px;

    .sectionRegister {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 554px;
      height: var(--heightPanel);

      background: var(--bg-panel);
      box-shadow: 13px 13px 50px #00000033;
      border-radius: 0 20px 20px 0;
      border: 1px solid var(--white);
      overflow: hidden;

      .sectionForm {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        width: 100%;
        animation: animationForm 0.5s linear;

        h1 {
          font-size: 1.8rem;
        }

        form {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          margin-top: 26px;

          button {
            width: 100%;
            margin-top: 40px;
          }
        }

        a {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-top: 30px;

          text-decoration: none;
          color: #000;
        }
      }
    }

    @media (max-width: 640px) {
      width: 100%;
      .sectionRegister {
        width: 90%;
        height: auto;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: none;
      }
    }
  }
}

@keyframes animationForm {
  0% {
    transform: translate(-75%, 0px);
    opacity: 0;
  }
  100% {
    transform: translate(0%, 0px);
    opacity: 1;
  }
}

@keyframes animationFormMobile {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
