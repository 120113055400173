@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // font-size: 16px (desktop)
  @media (max-width: 1080px) {
    font-size: 93.75%; // 15px
  }
  @media (max-width: 720px) {
    font-size: 87.5%; // 14px
  }
  // REM = 1rem = 16px

  --blue: #0766ff;
  --buttonDark: #ffffff;

  --text: #000;
  --text-hover: #fff;
  --label: #aab0b9;

  --color-icons: #000;
  --color-arrow: #000;
  --bg-panel: #f8f8f8;
  --bg-container: #ebebeb;
  --bg-containerModal: #ffffff;
  --bg-settings: #ffffff7a;
  --bg-option: #f8f8f8;
  --bg-linkUi: #efefef;
  --containerModal: #fff;
  --backgroundCardModal: #ececec;

  --heightPanel: 652px;

  --white: #fff;
  --black: #000;

  --background: #e9e9e9;
  --input: #fafafa;

  --border-color: #ffffff66;
  --backgroundSelect: hsl(0, 0%, 100%);
  --border: 1px solid #ffffff66;
  --border-panel: 1px solid #ebebeb;
  --border-settings: 1px solid #ffffff;

  --bg-phone: linear-gradient(90deg, rgba(237, 237, 237, 1) 0%, rgba(250, 251, 252, 1) 100%);
  --buttons-phone: inset 0 0 8px rgba(129, 129, 129, 0.3);
}

html[data-theme="dark"] {
  --color-icons: #fff;
  --color-arrow: #95b0db;

  --text: #fff;
  --text-hover: #000;
  --label: #9b9fab;

  --buttonDark: #0766ff;

  --bg-panel: #172437;
  --bg-container: #0a172a;
  --bg-settings: #95b0db1a;
  --bg-option: #233248;
  --bg-linkUi: #95b0db1a;
  --bg-containerModal: #172437;
  --containerModal: #0a172a;
  --backgroundCardModal: #95b0db1a;
  --backgroundSelect: hsl(215 29% 26%);

  --border-color: #95b0db66;
  --border: 1px solid #95b0db66;
  --border-panel: 1px solid #95b0db1a;
  --border-settings: 1px solid #ffffff1a;

  --color-headings: #3694ff;
  --color-text: #95b0db66;

  --bg-phone: linear-gradient(60deg, rgba(32,48,73,1) 0%, rgba(38,57,86,1) 100%);
  --buttons-phone: inset 0 0 8px rgba(32,48,73,1);

}

body {
  // background: #ebebeb;
  color: #000000;
  -webkit-font-smoothing: antialiased;

  .tox {

    .tox-notifications-container {
      display: none;
    }
    .tox-menu {
      background-color: var(--bg-option);
      border: var(--border);

      .tox-collection__group {
        .tox-menu-nav__js {
          background-color: var(--backgroundSelect);

          &.tox-collection__item--active {
            background-color: var(--backgroundSelect);
          }

          .tox-collection--list {
            background-color: var(--backgroundSelect);

            &:hover {
              background-color: var(--backgroundSelect);
            }
          }
          .tox-collection__item--enabled {
            background-color: var(--backgroundSelect);

            &:hover {
              background-color: var(--backgroundSelect);
            }
          }

          &:hover {
            background-color: var(--backgroundSelect);
          }
          .tox-collection__item-label {
            color: var(--text);
          }
        }

        .tox-collection__item--active {
          background-color: red;
        }
      }
    }
  }
}
body,
input,
textarea,
button {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
}
button {
  cursor: pointer;
}

ul li {
  list-style: none;
}

.effectPage {
  animation: effectPage 0.5s linear;
}

@keyframes effectPage {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0px);
  }
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 0.3s !important;
  transition-delay: 0 !important;
}

.headerPanelRight {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  h2 {
    font-size: 18px;
    color: var(--text);
  }
}

.mainPanelRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  // padding: 20px 12px;
  gap: 15px;

  // overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // @media (max-width: 1400px) {
  //   padding-top: 10px;
  // }
}


