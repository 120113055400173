select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: transparent;
  border-radius: 10px;
  background-image: none;
  padding-left: 20px;
  flex: 1;
  color: var(--text);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  option {
    background: var(--bg-option);
    color: var(--text);
  }

  &.selectDisabled {
    cursor: not-allowed;
  }

}
select::-ms-expand {
  display: none;
}
.customSelect {
  position: relative;
  display: flex;
  width: 100%;
  height: 55px;
  line-height: 3;
  box-shadow: 5px 5px 25px #0000000d;
  border: var(--border-settings);
  background: var(--bg-settings);
  border-radius: 10px;
  overflow: hidden;
  transition: border 0.1s;

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    pointer-events: none;
  }

  &.selectDisabled {
    cursor: not-allowed;
    opacity: 0.4;
    color: #474747;
  }
}
