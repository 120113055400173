.buttonLogin {
  width: 169px;
  height: 50px;
  border: none;
  border-radius: 25px;

  white-space: nowrap;
  font-size: 14px;
  color: var(--white);
  background: var(--blue);

  transition: background-color 0.3s;

  &:hover {
    color: #155d49;
    background: #20fcc0;
  }
}

.buttonDisabled {
  color: #767f8f;
  background-color: #ccd1d9;
}

.buttonRegister {
  white-space: nowrap;
  font-size: 14px;
  color: var(--text);
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 5px 5px 25px #0000000D;
  border: 1px solid var(--white);
  margin-left: 10px;

  transition: color 0.3s, border 0.3s;

  &:hover {
    color: var(--blue);
    border: 1px solid var(--blue);
  }
}

.ButtonCancel {
  width: 169px;
  height: 50px;
  border: none;
  border-radius: 25px;

  white-space: nowrap;
  font-size: 14px;
  color: #344257;
  background: #859ec5;

  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.9);
  }
}

.buttonDefault {
  white-space: nowrap;
  font-size: 14px;
  color: var(--text);
  background: #ffffff;
  border: 1px solid var(--bg-panel);
  border: 1px solid var(--white);
  margin-left: 10px;

  transition: color 0.3s, border 0.3s;

  &:hover {
    color: var(--blue);
    border: 1px solid var(--blue);
  }
}

.buttonBlur {
  width: 100%;
  height: 45px;

  background: var(--blue);
  border: none;
  box-shadow: 0px 5px 20px #0766FF26;
  border-radius: 45px;

  color: #000;
}
